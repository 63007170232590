import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const ProtectedRoute = ({ children }: any) => {
  const [getValueStorage] = useLocalStorage();
  const [initialRoute, setInitialRoute] = useState<any>("");

  useEffect(() => {
    const checkAuth = async () => {
      const authValue = await getValueStorage("chatID");
      try {
        if (authValue) {
          setInitialRoute(true);
        } else {
          setInitialRoute(false);
        }
      } catch (error) {
        console.error("Error checking auth value:", error);
      }
    };
    checkAuth();
  }, []);

  return initialRoute !== false ? children : <Navigate to="/register" />;
};

export default ProtectedRoute;
