import * as React from "react";

const Ellipse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 1440 900"
  >
    <g filter="url(#filter0_f_373_46)">
      <path
        fill="#E8E7F8"
        d="M1212 602.018c-51.17 190.957-309.519 287.647-577.047 215.963-114.033-30.555-240.383-140.41-341.547-239.058C-147.592 785.184 65.053 310.915 94.41 201.353c51.166-190.958 332.1 112.906 599.628 184.59 365.902-32.09 569.132 25.118 517.962 216.075"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_f_373_46"
        width="1802.33"
        height="1302.37"
        x="-282.512"
        y="-160.733"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feGaussianBlur
          result="effect1_foregroundBlur_373_46"
          stdDeviation="150"
        ></feGaussianBlur>
      </filter>
    </defs>
  </svg>
);

export default Ellipse;
