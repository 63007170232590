import * as React from "react";
import "./style.css";
import Logo from "../assets/images/logo";

type Props = {
  children?: React.ReactNode;
};

export default function LayoutMain({ children }: Props) {
  return (
    <>
      <header>
        <Logo />
      </header>
      <main id="main">{children}</main>
    </>
  );
}
