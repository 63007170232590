import axios from 'axios';

export const apiInstance = axios.create({
    baseURL: 'https://api-staging.migrator.me/',
});

export const api_heygen = axios.create({
  baseURL: 'https://api.heygen.com/v1',
});


// const HEYGEN_API_KEY2 =
// 'OTgyZmU2MWM1OGRiNDYxMGFlNzc3YTc1ZTliNjdlYTgtMTcyNTk4MjQ2Mw=='
// const HEYGEN_API_KEY =
//   'ZWNkODA1YWFhOTI1NDJjMjgwZmU5MjAyY2IzZjdiZmYtMTcyNTYxMTE4Mw==';
//   const HEYGEN_API_KEY3 =
//   'YjA5ZWM5Mjg2NDE2NDFhYWEzNWRmZjI0M2M5ZWQxMzUtMTcyNzM0NjMyMw==';
// // API Base URL
// const BASE_URL = 'https://api.heygen.com/v1';
// export const api_heygen = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     accept: 'application/json',
//     'content-type': 'application/json',
//     'x-api-key': HEYGEN_API_KEY3,
//   },
// });
