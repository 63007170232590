import React, { useEffect } from "react";
import LayoutMain from "../../layouts/main";
import TextInput from "../../components/chat/TextInput";
import ChatFlow from "../../components/chat/ChatFlow";
import { useSendInitMessage } from "../../utils/sendInitMessage";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useGetMessages } from "../../utils/getMessage";
import "./style.css";
import { useSendMessage } from "../../utils/sendMessage";
import InteractiveAvatar from "../../components/InteractiveAvatar/InteractiveAvatar";

export default function ChatPage() {
  const [messages, setMessages] = React.useState<any[]>([]);
  const { sendInitMessage } = useSendInitMessage();
  const [getValueStorage] = useLocalStorage();
  const { getMessage } = useGetMessages();
  const { sendMessage, loading } = useSendMessage();

  useEffect(() => {
    const getMessages = async () => {
      const sentInitMessage = await getValueStorage("isInitMessage");
      if (sentInitMessage === false) {
        const result: any = await sendInitMessage();
        setMessages(result?.messages);
      } else {
        const result: any = await getMessage();
        setMessages(result?.messages);
      }
    };
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSend = (text: string) => {
    setMessages([
      ...messages,
      {
        role: "user",
        content: [
          {
            type: "text",
            text: text,
          },
        ],
      },
    ]);
    sendMessage(text)
      .then((res) => {
        res?.messages?.length && setMessages(res?.messages);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const element = document.getElementById("textInputBox");
    if (element) element.scrollIntoView();
  }, [messages]);

  return (
    <LayoutMain>
      <div className="chat-wrapper">
        <InteractiveAvatar />
        <ChatFlow messages={messages} loading={loading} />
        <TextInput handleSend={handleSend} />
      </div>
    </LayoutMain>
  );
}
