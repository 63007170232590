import React from "react";

function MicrophoneIcon({ stroke = "#4F2392" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      fill="none"
      viewBox="0 0 16 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 10v2a7 7 0 01-7 7m0 0a7 7 0 01-7-7v-2m7 9v4m-4 0h8M8 1a3 3 0 00-3 3v8a3 3 0 006 0V4a3 3 0 00-3-3z"
      ></path>
    </svg>
  );
}

export default MicrophoneIcon;
