import { Button, Form, FormProps, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import TextArea from "antd/es/input/TextArea";
import "./registerForm.css";
import { useAuth } from "../../../context/AuthProvider";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { apiInstance } from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { Language } from "../selectLanguage/selectLanguage";

const { Option } = Select;

type FieldType = {
  name?: string;
  email?: string;
  country?: string;
  password?: string;
  knowFromUs?: string;
};

const userLanguage = {
  en: "English",
  ru: "Russian",
  es: "Spanish",
};

export default function RegisterForm() {
  const { t } = useTranslation();
  const [getValueStorage, setValueStorage] = useLocalStorage();
  const { setUser } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState<FieldType | null>(null);

  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    setIsModalOpen(true);
    setValues(values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.error("Failed:", errorInfo);
  };

  const confirmForm = async (values: FieldType) => {
    const lang: Language = await getValueStorage("LANGUAGE");
    apiInstance({
      method: "post",
      url: "/public/chat",
      data: {
        customerData: { ...values, user_language: userLanguage[lang || "en"] },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setValueStorage("chatID", res.data.id);
          setValueStorage("MigratorUserName", values.name);
          setUser([res.data.id]);
          navigate(`/`, { replace: true });
        }
      })
      .catch((e) => console.error(e));
  };

  return (
    <>
      <h1 className="mainTitle">{t("startConversation")}</h1>
      <Form
        className="form"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item<FieldType>
          hasFeedback
          label={t("name") + "*"}
          name="name"
          rules={[{ required: true, min: 2, max: 40, message: t("yourName") }]}
        >
          <Input variant="borderless" />
        </Form.Item>

        <Form.Item<FieldType>
          hasFeedback
          label={t("email") + "*"}
          name="email"
          rules={[
            {
              required: true,
              min: 2,
              max: 60,
              message: t("yourEmail"),
              type: "email",
            },
          ]}
        >
          <Input variant="borderless" />
        </Form.Item>

        <Form.Item<FieldType>
          hasFeedback
          name="country"
          rules={[{ required: true, message: t("selectCountry") }]}
        >
          <Select
            variant="borderless"
            placeholder={t("yourCountry")}
            allowClear
          >
            <Option value="en">{t("usa")}</Option>
            <Option value="es">{t("spain")}</Option>
            <Option value="ru">{t("russia")}</Option>
          </Select>
        </Form.Item>

        <Form.Item<FieldType>
          name="knowFromUs"
          label={t("knowFromUs")}
          rules={[{ required: false }]}
        >
          <TextArea variant="borderless" rows={4} maxLength={1000} />
        </Form.Item>

        <Form.Item
          className="buttonStartBox"
          style={{ width: "100%" }}
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Button className="buttonStart" type="default" htmlType="submit">
            {t("start")}
          </Button>
        </Form.Item>

        <Modal
          closeIcon={false}
          centered
          title={t("disclaimer")}
          open={isModalOpen}
          footer={[
            <Button key="back" onClick={() => setIsModalOpen(false)}>
              {t("return")}
            </Button>,
            <Button
              className="acceptContinue"
              key="submit"
              type="default"
              onClick={() => values && confirmForm(values)}
            >
              {t("acceptContinue")}
            </Button>,
          ]}
        >
          <p>{t("professionalDisclaimer")}</p>
        </Modal>
      </Form>
    </>
  );
}
