import { Button } from "antd";
import React, { useState } from "react";
import "./selectLanguage.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export type Language = "en" | "ru" | "es" | "";

interface ISelectLanguage {
  selectLanguage: (lang: Language) => void;
}

export default function SelectLanguage({ selectLanguage }: ISelectLanguage) {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<Language>("");

  const buttonSelect = (lang: Language) => {
    return lang === language ? "buttonSelect" : "";
  };

  const chooseLanguage = (lang: Language) => {
    i18next.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <div className="buttonBox">
      <div className="centerButtonBox">
        <h1 className="mainTitle">{t("selectLanguage")}</h1>
        <div className="buttonWrapper">
          <Button
            icon={
              <img
                alt=""
                src={require("../../../assets/images/USA.jpg")}
                width="100%"
                height="100%"
              />
            }
            className={`${buttonSelect("en")} countryButton`}
            onClick={() => chooseLanguage("en")}
            value="en"
          >
            <span className="btnText">{t("english")}</span>
          </Button>
          <Button
            icon={
              <img
                alt=""
                src={require("../../../assets/images/Spain.png")}
                width="100%"
                height="100%"
              />
            }
            className={`${buttonSelect("es")} countryButton`}
            onClick={() => chooseLanguage("es")}
            value="es"
          >
            <span className="btnText">{t("spanish")}</span>
          </Button>
          <Button
            icon={
              <img
                alt=""
                src={require("../../../assets/images/CountriesFlag.png")}
                width="100%"
                height="100%"
              />
            }
            className={`${buttonSelect("ru")} countryButton`}
            onClick={() => chooseLanguage("ru")}
            value="ru"
          >
            <span className="btnText">{t("russian")}</span>
          </Button>
        </div>
      </div>
      <Button
        onClick={() => selectLanguage(language)}
        className="buttonStart"
        type="default"
      >
        {t("continue")}
      </Button>
    </div>
  );
}
