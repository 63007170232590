type Storage =
  | "LANGUAGE"
  | "MigratorUserName"
  | "avatarIsShowing"
  | "chatID"
  | "isInitMessage";

function useLocalStorage<T>() {
  const getValueStorage = async (key: Storage) => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : false;
    } catch (error) {
      console.error(error);
      return key;
    }
  };

  const setValueStorage = (key: Storage, value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(value) : value;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [getValueStorage, setValueStorage] as const;
}

export default useLocalStorage;
