import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Logo from "../../../../assets/images/logo";
import "./style.css";
import SuccessProgress from "../../../progres";
import { useEffect, useState } from "react";

export default function Message({ message }: any) {
  const [percentage, setPercentage] = useState<number | null>(null);

  const roleStyle = (role: string) => {
    return role !== "user" ? "systemMesg" : "userMesg";
  };

  useEffect(() => {
    if (typeof message?.content === "string") {
      const percentageRegex = /(\d+)%/;
      const match = message?.content.match(percentageRegex);
      setPercentage(match ? parseInt(match[1], 10) : null);
    }
  }, [message?.content]);

  return (
    <li className={`${roleStyle(message.role)} message`}>
      {message.role !== "user" && (
        <div className="logoBox">
          <Logo width="95" height="19" />
        </div>
      )}

      <div className={`${roleStyle(message.role)}-message message-text`}>
        {percentage && <SuccessProgress percent={percentage} />}
        <Markdown remarkPlugins={[remarkGfm]}>
          {typeof message.content === "string"
            ? message?.content
            : message.content[0].text}
        </Markdown>
      </div>
    </li>
  );
}
