import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import i18next from "i18next";
import useLocalStorage from "./hooks/useLocalStorage";

import Register from "./pages/register";
import ProtectedRoute from "./routs/auth";
import ChatPage from "./pages/chat";
import PublicPage from "./pages/publicPage";
import ErrorPage from "./pages/errorPage";
import "./localization";
import PaymentSuccess from "./pages/payment-success";

const App: React.FC = () => {
  const [getValueStorage] = useLocalStorage();
  useEffect(() => {
    async function startSession() {
      const savedLanguage = (await getValueStorage("LANGUAGE")) || "en";
      if (savedLanguage) {
        i18next.changeLanguage(savedLanguage);
      }
    }
    startSession();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="register" element={<Register />} />
        <Route path="chat/:chatId" element={<PublicPage />} />
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <ChatPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
