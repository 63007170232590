import "./style.css";
import Message from "./message";
import { useTranslation } from "react-i18next";

interface IChatFlow {
  messages?: any;
  loading?: boolean;
}

export default function ChatFlow({ messages, loading }: IChatFlow) {
  const { t } = useTranslation();
  return (
    <div className="message-list-wrapper">
      <ul id="main" className="message-list">
        {messages?.map((message: any, index: number) => {
          return <Message key={index} message={message} index={index} />;
        })}
      </ul>
      {loading && <p>{t("loading")}...</p>}
    </div>
  );
}
