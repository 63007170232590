import React from "react";

export default function SuccessProgress({ percent = 0 }: { percent: number }) {
  const radius = 50; // Circle radius
  const strokeWidth = 13; // Stroke width
  const circumference = 2 * Math.PI * radius; // Circle circumference
  const progress = (percent / 100) * circumference; // Progress length

  const angle = (percent / 100) * 360;
  const starX = 60 + radius * Math.cos((angle * Math.PI) / 170);
  const starY = 60 + radius * Math.sin((angle * Math.PI) / 180);

  const v = percent > 40 && percent < 70 ? +15 : -10;
  return (
    <div
      style={{
        display: "inline-block",
        transform: "rotate(-90deg)", // Rotate the entire SVG by 45 degrees (adjust as needed)
        transformOrigin: "center", // Ensures rotation happens around the center
      }}
    >
      <svg width={100 + v} height={100} viewBox="0 0 120 120">
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke="#E1586E"
          strokeWidth={strokeWidth}
        />
        <defs>
          <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#19306D" />
            <stop offset="100%" stopColor="#496ECF" />
          </linearGradient>
        </defs>
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke="url(#gradientStroke)"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          strokeLinecap="round"
        />
        <text
          x="65"
          y="65"
          textAnchor="middle"
          dy=".3em"
          fontSize="30"
          fill="#1F3879"
          style={{
            fontWeight: 700,
            display: "inline-block",
            transform: "rotate(90deg)",
            transformOrigin: "center",
          }}
        >
          {percent}%
        </text>
        <g transform={`translate(${starX - 40}, ${starY - 25})`}>
          <svg width="80" height="80" fill="none" viewBox="0 0 60 60">
            <g clipPath="url(#a)" filter="url(#b)">
              <path
                fill="#1F3879"
                d="M26 32c6.627 0 12-5.373 12-12S32.627 8 26 8s-12 5.373-12 12 5.373 12 12 12"
              ></path>
              <path
                fill="#fff"
                d="m25.888 12.91 2.151 4.353 4.802.698-3.475 3.388.82 4.783-4.298-2.256-4.295 2.256.82-4.783-3.474-3.388 4.801-.698z"
              ></path>
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
}
