import * as React from "react";

const LogoSignIn = ({ width = "227", height = "46" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 227 46"
  >
    <path
      fill="#fff"
      d="M31.703 7.853v27.902h-3.379V7.853zM3.542 35.755H0L10.245 7.853h3.488l10.245 27.902h-3.542l-8.338-23.488h-.218zm1.308-10.9h14.278v2.998H4.85z"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M190.638 46c10.678 0 19.388-8.709 19.388-19.387s-8.71-19.388-19.388-19.388-19.388 8.71-19.388 19.388S179.959 46 190.638 46"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M181.171 9.69c-1.865.913-4.613 2.01-6.376.723-1.763-1.288-2.722-4.544-2.57-8.653-7.369 16.718-5.21 29.679 5.464 39.283z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M178.244 9.046a4.04 4.04 0 0 0 4.034-4.034A4.04 4.04 0 0 0 178.244.98a4.04 4.04 0 0 0-4.033 4.033 4.04 4.04 0 0 0 4.033 4.034"
      clipRule="evenodd"
    ></path>
    <path
      fill="#093161"
      fillRule="evenodd"
      d="M190.637 42.974c9.013 0 16.363-7.35 16.363-16.362 0-9.013-7.35-16.363-16.363-16.363s-16.362 7.35-16.362 16.363 7.35 16.362 16.362 16.362"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FEFEFE"
      fillRule="evenodd"
      d="m190.118 12.026.75-.007c7.955.123 14.366 6.61 14.366 14.594 0 8.062-6.535 14.596-14.597 14.596s-14.597-6.534-14.597-14.596c0-7.888 6.257-14.313 14.078-14.587"
      clipRule="evenodd"
    ></path>
    <path
      fill="#BE0E33"
      fillRule="evenodd"
      d="m190.117 12.026.751-.007a14.53 14.53 0 0 1 7.631 2.292l-9.078.089-.022-2.331q.358-.03.718-.043M189.444 16.705l11.805-.114a14.6 14.6 0 0 1 1.807 2.349l-13.59.132zM189.488 21.309l14.695-.143q.459 1.137.723 2.36l-15.395.15zM189.532 25.841l15.673-.152q.029.458.03.924-.001.73-.071 1.443l-15.609.152zM176.564 30.5l28.219-.273a14.5 14.5 0 0 1-.83 2.375l-26.512.257a14.6 14.6 0 0 1-.877-2.359M178.798 35.152l23.842-.231a14.7 14.7 0 0 1-2.069 2.387l-19.658.19a14.7 14.7 0 0 1-2.115-2.346M184.173 39.702l13.181-.128a14.5 14.5 0 0 1-6.717 1.635 14.5 14.5 0 0 1-6.464-1.507"
      clipRule="evenodd"
    ></path>
    <path
      fill="#093161"
      fillRule="evenodd"
      d="m190.118 12.026.605-.006.157 16.174-14.738.143a15 15 0 0 1-.102-1.724c0-7.888 6.257-14.314 14.078-14.587"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FEFEFE"
      fillRule="evenodd"
      d="m182.539 14.467.217.643-.565-.404q.172-.122.348-.239M187.802 13.026l.259.776.818-.01-.658.486.262.775-.666-.475-.657.488.247-.78-.667-.473.818-.007zM179.952 16.67l.541-.006-.657.486.262.775-.666-.476-.377.28q.424-.55.897-1.06M184.882 15.843l.259.776.819-.01-.659.487.262.775-.665-.476-.657.489.247-.78-.668-.473.819-.008zM177.822 19.62h.008l-.012.008zm-.486.973.097.287-.17-.121zM182.13 18.8l.259.776.818-.01-.658.486.262.776-.666-.477-.656.489.247-.78-.668-.473.819-.006zM187.857 18.745l.259.776.819-.01-.659.486.262.775-.665-.475-.657.488.247-.78-.668-.473.819-.008.243-.78M179.472 21.52l.259.776.818-.009-.658.486.262.776-.667-.476-.656.488.247-.78-.667-.473.818-.007zM184.938 21.466l.259.777.817-.01-.657.486.261.775-.665-.475-.657.488.247-.78-.668-.474.819-.005zM176.81 24.833l.258.776.819-.01-.658.486.261.775-.665-.475-.656.488.246-.78-.354-.252q.005-.111.014-.224l.492-.004zM182.188 24.78l.26.776.818-.01-.659.486.262.775-.665-.475-.657.488.247-.78-.668-.473.819-.007.243-.782zM187.915 24.725l.259.776.818-.01-.658.486.262.775-.666-.475-.656.488.247-.78-.668-.473.818-.006z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M44.253 36.429h-5.167V8.141h3.292l11.33 16.498L65.002 8.14h3.33V36.43h-5.206V19.663l-8.574 12.402h-1.723l-8.575-12.402zm32.423-23.274q-1.378 0-2.297-.88t-.919-2.258q0-1.34.938-2.24.939-.9 2.278-.9 1.34 0 2.24.9.899.9.899 2.24 0 1.377-.9 2.258-.9.88-2.24.88m2.45 2.489v20.785H74.11V15.644zm21.015 2.487v-2.487h4.67v18.718q0 4.977-3.177 7.924t-8.269 2.948q-5.282-.001-9.455-2.987l2.03-3.789q3.483 2.45 7.31 2.45 3.14 0 4.939-1.665t1.799-4.383v-1.225q-2.526 2.832-6.623 2.832-4.554 0-7.464-3.024t-2.91-7.617q0-4.67 3.025-7.656 3.025-3.024 7.54-3.024 3.982 0 6.585 2.985m-6.048 13.972q2.603 0 4.325-1.76 1.723-1.799 1.723-4.48 0-2.679-1.723-4.401-1.722-1.76-4.325-1.76-2.642 0-4.364 1.76-1.723 1.722-1.723 4.402t1.723 4.479q1.722 1.76 4.364 1.76m20.747 4.326h-4.977V15.644h4.785v2.87q1.915-3.406 5.781-3.406 1.302 0 2.488.42l-.421 4.786q-1.34-.421-2.412-.421-2.411 0-3.828 1.626-1.416 1.628-1.416 4.766zm26.03-18.145v-2.64h4.746v20.785h-4.746v-2.565q-2.718 3.063-6.776 3.062-4.478 0-7.387-3.138-2.91-3.139-2.91-7.847 0-4.71 2.91-7.733 2.946-3.062 7.387-3.062 3.981 0 6.776 3.138m.114 7.733q0-2.64-1.722-4.479-1.723-1.837-4.402-1.837t-4.402 1.837q-1.684 1.876-1.684 4.48 0 2.64 1.684 4.516 1.723 1.837 4.402 1.837t4.402-1.837 1.722-4.517m22.853 5.283 1.569 3.904q-2.45 1.722-6.009 1.722-7.12 0-7.12-8.115v-8.956h-3.445v-4.211h3.521v-7.35h4.938v7.35h6.967v4.21h-6.967v8.766q0 3.75 3.024 3.751 1.761 0 3.522-1.071m54.673 5.129h-4.976V15.644h4.785v2.87q1.913-3.406 5.78-3.406 1.301 0 2.488.42l-.421 4.786q-1.34-.421-2.412-.421-2.412 0-3.828 1.626-1.416 1.628-1.416 4.766z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default LogoSignIn;
