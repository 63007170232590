import { useCallback, useState } from "react";
import { apiInstance } from "../api/axios";
import useLocalStorage from "../hooks/useLocalStorage";

export const useSendMessage = () => {
  const [getValueStorage, setValueStorage] = useLocalStorage();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendMessage = useCallback(async (text: string) => {
    setLoading(true);
    setError(null);

    try {
      const chatID = await getValueStorage("chatID");

      const res = await apiInstance({
        method: "post",
        url: "/public/chat",
        data: {
          conversationId: chatID,
          newMessage: {
            role: "user",
            content: [
              {
                type: "text",
                text: text,
              },
            ],
          },
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (e: any) {
      console.error(e);
      setError(e.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, []);

  return { sendMessage, loading, error };
};
