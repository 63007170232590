import React from "react";

function SentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.092 11H4.536m15.556 0L1 20.192 4.536 11m15.556 0L1 1.808 4.536 11"
      ></path>
    </svg>
  );
}

export default SentIcon;
