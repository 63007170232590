import React, { useEffect, useState } from "react";
import LayoutMain from "../../layouts/main";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Button } from "antd";
import "./payment-success.css";
import { useTranslation } from "react-i18next";

export default function PaymentSuccess() {
  const [getValueStorage] = useLocalStorage();
  const [returnToChat, setReturnToChat] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const checkAuth = async () => {
      const authValue = await getValueStorage("chatID");
      try {
        if (authValue) {
          setReturnToChat(true);
        } else {
          setReturnToChat(false);
        }
      } catch (error) {
        console.error("Error checking auth value:", error);
      }
    };
    checkAuth();
  }, []);

  return (
    <LayoutMain>
      <h1 className="PaymentSuccess title">{t("thePaymentIsSuccessful")}</h1>
      {returnToChat ? (
        <Button href="/" className="buttonReturn" type="primary">
          {t("returnToChat")}
        </Button>
      ) : (
        <h3>{t("backToApp")}</h3>
      )}
    </LayoutMain>
  );
}
