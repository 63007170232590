import { Button } from "antd";
import LayoutMain from "../../layouts/main";

export default function ErrorPage() {
  return (
    <LayoutMain>
      <h3>Page NOT found</h3>
      <Button href="/">Got to Main Page</Button>
    </LayoutMain>
  );
}
