import { Button } from "antd";
import React, { useEffect, useState } from "react";
import MicrophoneIcon from "../../assets/images/microphone";
import "./style.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useLocalStorage from "../../hooks/useLocalStorage";

interface ISpeechRecognitions {
  setRecording: (test: string) => void;
}

export default function SpeechRecognitions({
  setRecording,
}: ISpeechRecognitions) {
  const { interimTranscript, resetTranscript, finalTranscript, listening } =
    useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [getValueStorage] = useLocalStorage();

  const handleListing = async () => {
    const lang = await getValueStorage("LANGUAGE");
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
      interimResults: false,
      language: lang,
    });
  };
  const handleReset = () => {
    setIsListening(false);
    SpeechRecognition.stopListening();
    resetTranscript();
  };
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    alert("Browser does not support speech recognition");
  }

  useEffect(() => {
    if (interimTranscript.length) {
      setRecording(interimTranscript);
    }
  }, [interimTranscript]);

  useEffect(() => {
    handleReset();
  }, [finalTranscript]);

  return (
    <>
      {isListening ? (
        <Button onClick={handleReset} className="microphoneIconButton">
          <MicrophoneIcon stroke="red" />
          {listening && "..."}
        </Button>
      ) : (
        <Button onClick={handleListing} className="microphoneIconButton">
          <MicrophoneIcon />
        </Button>
      )}
    </>
  );
}
