import Ellipse from "../../assets/images/ellipse";
import Logo from "../../assets/images/logo";
import LogoSignIn from "../../assets/images/logoSignIn";
import FormBox from "../../components/formBox";
import "./register.css";

export default function Register() {
  return (
    <div className="register">
      <div className="logoBox">
        <LogoSignIn />
      </div>
      <FormBox />

      <div className="usFlag">
        <img
          alt=""
          src={require("../../assets/images/usFlag.png")}
          width="484px"
          height="484px"
        />
      </div>
      <div className="statueOfLiberty">
        <img
          alt=""
          src={require("../../assets/images/StatueOfLiberty.png")}
          width="100%"
          height="100%"
        />
      </div>
      <div className="ellipseShadow">
        <Ellipse />
      </div>
    </div>
  );
}
