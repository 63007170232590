import { useCallback, useState } from "react";
import { apiInstance } from "../api/axios";
import useLocalStorage from "../hooks/useLocalStorage";
import { prompts } from "./prompts";

type Languages = "ru" | "en" | "es" | null | undefined;

export const useSendInitMessage: () => {
  sendInitMessage: () => Promise<void>;
  loading: boolean;
  error: string | null;
} = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [getValueStorage, setValueStorage] = useLocalStorage();

  const sendInitMessage = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const lang = (await getValueStorage("LANGUAGE")) as Languages;

      const chatID = await getValueStorage("chatID");
      const prompt = prompts[lang ?? "en"];

      const res = await apiInstance({
        method: "post",
        url: "/public/chat",
        data: {
          conversationId: chatID,
          newMessage: { role: "user", content: prompt },
        },
      });

      if (res.status === 200) {
        setValueStorage("isInitMessage", "true");
        return res.data;
      }
    } catch (e: any) {
      console.error(e);
      setError(e || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, []);

  return { sendInitMessage, loading, error };
};
