import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import SentIcon from "../../../assets/images/sent";
import "./style.css";
import SpeechRecognition from "../../speechRecognition";
import { useTranslation } from "react-i18next";

interface ITextInput {
  handleSend: (msg: string) => void;
}

export default function TextInput({ handleSend }: ITextInput) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");

  const handleButtonClick = () => {
    handleSend(message);
    setMessage("");
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };

  useEffect(() => {
    const element = document.getElementById("textInputBox");
    if (element) element.scrollIntoView();
  }, [message]);

  return (
    <div id="textInputBox" className="textInputBox">
      <Input
        onKeyDown={handleKeyPress}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        size="large"
        placeholder={t("askMeAnything")}
        suffix={<SpeechRecognition setRecording={setMessage} />}
      />
      <Button onClick={handleButtonClick} type="primary" className="sendButton">
        <SentIcon />
      </Button>
    </div>
  );
}
