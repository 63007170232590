import React, { useEffect } from "react";
import LayoutMain from "../../layouts/main";
import ChatFlow from "../../components/chat/ChatFlow";

import { useGetMessages } from "../../utils/getMessage";
import { useParams } from "react-router-dom";

export default function PublicPage() {
  const { chatId } = useParams();
  const [messages, setMessages] = React.useState<any[]>([]);
  const { getMessage } = useGetMessages();

  useEffect(() => {
    const getMessages = async () => {
      const result: any = await getMessage(chatId);
      setMessages(result?.messages);
    };
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutMain>
      <div className="chat-wrapper">
        <ChatFlow messages={messages} loading={false} />
      </div>
    </LayoutMain>
  );
}
