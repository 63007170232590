import { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import "./formBox.css";
import RegisterForm from "./registerForm/registerForm";
import SelectLanguage from "./selectLanguage/selectLanguage";
import { useTranslation } from "react-i18next";

export default function FormBox() {
  const [language, setSelectLanguage] = useState<any>();
  const { t } = useTranslation();

  const [getValueStorage, setValueStorage] = useLocalStorage();

  const selectLanguage = (lang: string) => {
    setValueStorage("LANGUAGE", lang);
    setSelectLanguage(lang);
  };

  return (
    <div className={`formBox ${!language ? "formBoxHeight" : ""}`}>
      <div className="formWrapper">
        {language ? (
          <RegisterForm />
        ) : (
          <SelectLanguage selectLanguage={selectLanguage} />
        )}
      </div>
    </div>
  );
}
